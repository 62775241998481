import {
  Absences,
  AbsencesInMonthlyOverview,
  AbsencesInYearlyOverview,
  ChangeHistoryEntry,
  UpcomingAbsences,
  User,
  UserMetaData,
  UserRequestAccuracy
} from "@/models";
import {namespace} from "vuex-class";
import {ActionContext, Module} from "vuex";
import axios from "@/plugins/axios";

export interface AbsenceState {
  absencesInMonthlyOverview: Absences<AbsencesInMonthlyOverview>;
  absencesInYearlyOverview: Absences<AbsencesInYearlyOverview>;
  upcomingAbsences: UpcomingAbsences[];
  users: User[];
  validUsers: User[];
  selectedUsers: User[];
  changeHistory: ChangeHistoryEntry[];
  userMetaData: UserMetaData;
}

export const ABSENCE_DATA_MODULE_NAME = "absenceData";

export const ABSENCE_DATA_GETTERS = {
  getAllAbsencesInYear: "getAllAbsencesInYear",
  getAllAbsencesInMonth: "getAllAbsencesInMonth",
  getAllUpcomingAbsences: "getAllUpcomingAbsences",
  getAllUsers: "getAllUsers",
  getAllValidUsers: "getAllValidUsers",
  getSelectedUsers: "getSelectedUsers",
  getChangeHistory: "getChangeHistory",
  getUserMetaData: "getUserMetaData"
};

export const ABSENCE_DATA_MUTATIONS = {
  setYearlyAbsences: "setYearlyAbsences",
  setMonthlyAbsences: "setMonthlyAbsences",
  setUpcomingAbsences: "setUpcomingAbsences",
  setUsers: "setUsers",
  setValidUsers: "setValidUsers",
  setSelectedUsers: "setSelectedUsers",
  setChangeHistory: "setChangeHistory",
  reverseChangeHistory: "reverseChangeHistory",
  setUserMetaData: "setUserMetaData",
  removeAbsenceRequest: "removeAbsenceRequest"
};

export const ABSENCE_DATA_ACTIONS = {
  fetchAllUsers: "fetchAllUsers",
  fetchAllValidUsers: "fetchAllValidUsers",
  fetchAbsencesOfYear: "fetchAbsencesOfYear",
  fetchAbsencesOfMonth: "fetchAbsencesOfMonth",
  fetchUpcomingAbsences: "fetchUpcomingAbsences",
  fetchChangeHistory: "fetchChangeHistory",
  fetchUserMetaData: "fetchUserMetaData"
};

export const AbsenceSpace = namespace(ABSENCE_DATA_MODULE_NAME);

function getAbsencesFromYearURLBuilder(year: number) {
  return "/api/getAbsencesFromYear/" + year;
}

function getAllValidUsersURLBuilder(userRequestAccuracy: UserRequestAccuracy) {
  const year = userRequestAccuracy.year;
  const month = userRequestAccuracy.month;
  if (year == null) {
    return "/api/getAllUsers";
  }
  if (!month) {
    return "/api/getAllValidUsers/" + year;
  }
  return "/api/getAllValidUsersInMonth/" + year + "/" + month;
}

function getMonthlyAbsencesURLBuilder(year: number, month: number) {
  return "/api/getAbsencesFromMonth/" + year + "/" + month;
}

function getChangeHistoryURLBuilder(userId: string) {
  return "/api/getChangeHistoryForUser/" + userId;
}

function emptyObjectCheck<T>(value: T) {
  if (Object.keys(value as object).length > 0) {
    return value;
  } else {
    return null;
  }
}

function commitValuesToStore<T>(
  mutation: string,
  context: ActionContext<AbsenceState, any>,
  valueConverter: (someValue: T) => T | null = value =>
    (value as unknown) as T | null // Default value: Identity function
): (value: T) => T | null {
  return (value: T) => {
    const convertedValue = valueConverter(value);
    context.commit(mutation, convertedValue);
    /*
    Quick and dirty copy to safely make fetched value available for use in components
    without risking that the object in vuex is modified without using a mutations.
    Has some limitations, see https://stackoverflow.com/a/122704
    */
    return JSON.parse(JSON.stringify(convertedValue)) as T | null;
  };
}

const jsonHeader = { "Content-Type": "application/json" };

export default class AbsenceData implements Module<AbsenceState, any> {
  namespaced = true;

  getters = {
    getAllAbsencesInYear(thisState: AbsenceState) {
      return thisState.absencesInYearlyOverview;
    },
    getAllAbsencesInMonth(thisState: AbsenceState) {
      return thisState.absencesInMonthlyOverview;
    },
    getAllUpcomingAbsences(thisState: AbsenceState) {
      return thisState.upcomingAbsences;
    },
    getAllUsers(thisState: AbsenceState) {
      return thisState.users;
    },
    getAllValidUsers(thisState: AbsenceState) {
      return thisState.validUsers;
    },
    getSelectedUsers(thisState: AbsenceState) {
      return thisState.selectedUsers;
    },
    getChangeHistory(thisState: AbsenceState) {
      return thisState.changeHistory;
    },
    getUserMetaData(thisState: AbsenceState) {
      return thisState.userMetaData;
    }
  };

  mutations = {
    setYearlyAbsences(
      thisState: AbsenceState,
      newAbsences: Absences<AbsencesInYearlyOverview>
    ) {
      thisState.absencesInYearlyOverview = newAbsences;
    },
    setMonthlyAbsences(
      thisState: AbsenceState,
      newAbsences: Absences<AbsencesInMonthlyOverview>
    ) {
      thisState.absencesInMonthlyOverview = newAbsences;
    },
    setUpcomingAbsences(
      thisState: AbsenceState,
      newAbsences: UpcomingAbsences[]
    ) {
      thisState.upcomingAbsences = newAbsences;
    },
    setUsers(thisState: AbsenceState, users: User[]) {
      thisState.users = users;
    },
    setValidUsers(thisState: AbsenceState, users: User[]) {
      thisState.validUsers = users || [];
    },
    setSelectedUsers(thisState: AbsenceState, selectedUsers: User[]) {
      thisState.selectedUsers = selectedUsers;
    },
    setChangeHistory(
      thisState: AbsenceState,
      changeHistory: ChangeHistoryEntry[]
    ) {
      thisState.changeHistory = changeHistory || [];
    },
    reverseChangeHistory(thisState: AbsenceState) {
      // .reverse works in-place
      (thisState.changeHistory || []).reverse();
    },
    setUserMetaData(thisState: AbsenceState, userMetaData: UserMetaData) {
      thisState.userMetaData = userMetaData;
    }
  };

  actions = {
    async fetchAllUsers(context: ActionContext<AbsenceState, any>) {
      return axios
        .get("/api/getAllUsers")
        .then(response => response.data)
        .then(users =>
          commitValuesToStore(
            ABSENCE_DATA_MUTATIONS.setUsers,
            context,
            emptyObjectCheck
          )(users)
        );
    },
    async fetchAllValidUsers(
      context: ActionContext<AbsenceState, any>,
      requestAccuracy: UserRequestAccuracy
    ) {
      return axios
        .get(getAllValidUsersURLBuilder(requestAccuracy))
        .then(response => response.data)
        .then(validUsers =>
          commitValuesToStore(
            ABSENCE_DATA_MUTATIONS.setValidUsers,
            context,
            emptyObjectCheck
          )(validUsers)
        );
    },
    async fetchAbsencesOfYear(
      context: ActionContext<AbsenceState, any>,
      [year, users]: [number, string[]]
    ) {
      return axios
        .post(getAbsencesFromYearURLBuilder(year), users, {
          headers: jsonHeader
        })
        .then(response => response.data)
        .then(absencesOfYear =>
          commitValuesToStore(
            ABSENCE_DATA_MUTATIONS.setYearlyAbsences,
            context,
            emptyObjectCheck
          )(absencesOfYear)
        );
    },
    async fetchAbsencesOfMonth(
      context: ActionContext<AbsenceState, any>,
      [year, month, users]: [number, number, string[]]
    ) {
      return axios
        .post(getMonthlyAbsencesURLBuilder(year, month), users, {
          headers: jsonHeader
        })
        .then(response => response.data)
        .then(absencesOfMonth =>
          commitValuesToStore(
            ABSENCE_DATA_MUTATIONS.setMonthlyAbsences,
            context,
            emptyObjectCheck
          )(absencesOfMonth)
        );
    },
    async fetchUpcomingAbsences(context: ActionContext<AbsenceState, any>) {
      return axios
        .get("/api/getUpcomingAbsences", {
          headers: jsonHeader
        })
        .then(response => response.data)
        .then(upcomingAbsences =>
          commitValuesToStore(
            ABSENCE_DATA_MUTATIONS.setUpcomingAbsences,
            context,
            emptyObjectCheck
          )(upcomingAbsences)
        );
    },
    async fetchChangeHistory(
      context: ActionContext<AbsenceState, any>,
      user: string
    ) {
      return axios
        .get(getChangeHistoryURLBuilder(user))
        .then(response => response.data)
        .then(changeHistory =>
          commitValuesToStore(
            ABSENCE_DATA_MUTATIONS.setChangeHistory,
            context,
            emptyObjectCheck
          )(changeHistory)
        );
    },
    async fetchUserMetaData(context: ActionContext<AbsenceState, any>) {
      return axios
        .get("/api/getUserMeta")
        .then(response => response.data)
        .then(userMeta =>
          commitValuesToStore(
            ABSENCE_DATA_MUTATIONS.setUserMetaData,
            context,
            emptyObjectCheck
          )(userMeta)
        );
    }
  };

  constructor(
    public state: AbsenceState = {
      absencesInMonthlyOverview: {},
      absencesInYearlyOverview: {},
      upcomingAbsences: [],
      users: [],
      validUsers: [],
      selectedUsers: [],
      changeHistory: [],
      userMetaData: {} as UserMetaData
    }
  ) {}
}
