import {
  DEFAULT_TIMEZONE,
  FULL_DAY_LEAVE,
  HALF_DAY_LEAVE,
  MINIMAL_RESPONSIVE_WIDTH,
  REG_EXP_EMAIL,
  VACATION_TYPES,
  VacationTypesByAbbreviation,
  WeekDays
} from "./constants";
import {UserMetaData} from "@/models";
import {isAfter, isBefore} from "date-fns";
import de from "date-fns/locale/de";
import {format, FormatOptionsWithTZ} from "date-fns-tz";

export function findKeyByValue(object: any, value: any): string {
  const result = Object.keys(object).find(key => object[key] === value);
  return result || "";
}

export function getWeekDayEnum(option: string): number {
  return (WeekDays as any)[option];
}

export function getVacationAbbrByType(vacationType: string) {
  const vacationTypeIndex = VACATION_TYPES.indexOf(vacationType);

  return (VacationTypesByAbbreviation as any)[vacationTypeIndex];
}

export function validateEmail(email: string) {
  return REG_EXP_EMAIL.test(String(email).toLowerCase());
}

export function validateFlaviaReceipients(email: string) {
  const mail = String(email).toLowerCase()
  return (mail.endsWith("@flavia-it.de") || mail.endsWith( "@gridundco.de"));
}

// to be used as :rules="required('this field is required')"
export function required(errorMessage: string): ((v:any)=>boolean|string)[] {
  return [ (v) => !!v || errorMessage]
}

export function getUserMessageForErrorReason(reason: any): string {
  if (reason.response.status === 400) {
    switch (reason.response.data) {
      case "Email is not unique":
        return "Angegebene E-Mail-Adresse wird bereits verwendet";
      case "Couldn't map Json":
        return "Beim Lesen der Daten ist ein Fehler aufgetreten";
      case "The given email wasn't valid":
      case "Email is not valid":
        return "Angegebene E-Mail-Adresse ist ungültig";
      case "Invalid data":
        return "Die eingegebenen Daten waren fehlerhaft";
      case "Couldn't authenticate creator":
        return "Es gab einen Fehler bei der Authentifizierung, möglicherweise ist die Sitzung abgelaufen";
      case "startOfRange should be before endOfRange":
      case "Only absences from the same year can be merged":
        return "Ungültiger Änderungszeitraum";
      case "The vacation request has already been cancelled":
        return "Der Urlaubsantrag wurde bereits storniert";
      case "Absence Request Not Found":
        return "Der Urlaubsantrag wurde nicht gefunden";
      case "No corresponding absenceRequest":
        return "Kein entsprechender Urlaubsantrag gefunden";
      case "No user with such an id":
        return "Keine Person mit einer solchen ID gefunden";
      case "A problem has occurred while trying to get all changes":
      case "A problem has occurred while trying to get all Users.":
        return "Beim Versuch, die Informationen abzurufen, ist ein Problem aufgetreten";
      case "User's `firstName` or `lastName` parameters are empty":
      case "User's `firstName` or `lastName` are not correctly defined":
        return "Die Personendaten sind nicht vollständig oder falsch angegeben";
      case "There are no entries that could be deleted":
        return "Es gibt keine Einträge, die gelöscht werden könnten";
      case "User doesn't seem to have an absence status in given year":
        return "Die Person scheint im angegebenen Jahr keine Urlaubsanträge zu haben";
      case "Incorrect data":
        return "Einige Daten waren fehlerhaft.";
      default:
        return (
          "Es ist ein Fehler aufgetreten. Fehlermeldung: " +
          reason.response.data
        );
    }
  } else if (reason.response.status === 401) {
    return "Sie sind nicht angemeldet";
  } else if (reason.response.status === 404) {
    return (
      "Eine angefragte Resource konnte nicht gefunden werden. Fehlermeldung: " +
      reason.response.data
    );
  } else {
    return (
      "Es ist ein unvorhergesehener Fehler aufgetreten: " + reason.response.data
    );
  }
}

export function getDateContext(date: number[] | string | number): Date {
  if (Number(date) === date) {
    date = Number(date);
  }

  if (Array.isArray(date)) {
    return new Date(date[0], date[1] - 1, date[2]);
  }

  if (typeof date === "string") {
    return new Date(date);
  }
  if (Number.isInteger(date)) {
    const epochSeconds = date * 1000;
    return new Date(epochSeconds);
  }

  throw new Error("Invalid date format");
}

export function roundToTwoDecimals(potentiallyLongNumber: number) {
  return Number(potentiallyLongNumber.toFixed(2));
}

export function formatWorkingHoursInMinutes(hoursInMinutes: number): string {
  if (!hoursInMinutes) {
    return "–";
  }

  return `${Math.floor(hoursInMinutes / 60)}h ${hoursInMinutes % 60}m`;
}

export function vacationValueOfString(inputString: string): number {
  if (inputString) {
    if (inputString === getVacationAbbrByType(FULL_DAY_LEAVE)) {
      return 1;
    }
    if (inputString === getVacationAbbrByType(HALF_DAY_LEAVE)) {
      return 0.5;
    }
  }
  return 0;
}

export function hasReadRights(userMetadata: UserMetaData): boolean {
  return (
    (userMetadata && userMetadata.isAdmin) ||
    userMetadata.isReviewer ||
    userMetadata.isManager
  );
}

export function isOnMobileBrowser(): boolean {
  return window.screen.width <= MINIMAL_RESPONSIVE_WIDTH;
}

export function isBetween(
  date: Date,
  lowerLimit: Date,
  upperLimit: Date
): boolean {
  return !isAfter(date, upperLimit) && !isBefore(date, lowerLimit);
}
export function isWeekend(date: Date) {
  return [0, 6].includes(date.getDay());
}

export function formatDateToFormatStringInUTCTz(
  date: Date | number,
  formatString: string
): string {
  const options: FormatOptionsWithTZ = {
    timeZone: DEFAULT_TIMEZONE,
    // @ts-expect-error: This is straight from the example file
    locale: de
  };
  return format(date, formatString, options);
}
