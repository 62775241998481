<template>
  <div class="request-form-layout">

    <v-snackbar
      v-model="snackbar"
      :color="transactionError ? 'error' : 'success'"
      top
    >
      {{ transactionStatus }}
      <v-btn color="white" flat @click="onCloseSnackbar"> Schließen</v-btn>
    </v-snackbar>
    <form>
      <div id="loadingSpinner" v-if="loading"></div>
      <div>
        <template>
          <v-layout>
            <v-flex class="" cols="12" sm="6" md="4">
              <date-picker
                label="Ab wann?"
                date-picker-type="from-date"
                :menu-active="fromMenu"
                :selected-date="fromDate"
                :is-date-allowed="date => isDateAllowed(date, 'toDate')"
                @update:menuActive="fromMenu = $event"
                @update:selectedDate="date => updateFromDate(date)"
              />
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex cols="12" sm="6" md="4">
              <date-picker
                label="Bis wann?"
                date-picker-type="to-date"
                :menu-active="toMenu"
                :selected-date="toDate"
                :is-date-allowed="(date) => isDateAllowed(date, 'fromDate')"
                @update:menuActive="toMenu = $event"
                @update:selectedDate="(date) => updateToDate(date)"
              />
            </v-flex>
          </v-layout>
        </template>
        <div class="contact-selection" @keydown.enter="handleEnteredEmail">
          <v-autocomplete
            v-model="selectedContacts"
            :search-input.sync="searchText"
            :placeholder="placeHolderUserSelection"
            multiple
            ref="mailingsList"
            label="Empfängerliste:"
            :items="availableContacts"
            item-value="email"
            :filter="customFilter"
            @change="change"
            :disabled="!usersLoaded"
            :no-data-text="placeholderNoSelectableData"
            hide-selected
          >
            <template v-slot:selection="data">
              <v-chip
                :selected="data.selected"
                close
                class="chip--select-multi"
                @input="() => clearSelectedContact(data.item)"
              >
                <span v-if="data.item.firstName">{{ data.item.firstName }} {{ data.item.lastName }}</span>
                <span v-else>{{ data.item.email }}</span>
              </v-chip>
            </template>
            <template v-slot:item="data">
              <div class="selectable-user-option">
                <img v-if="data.item.ldapName" onerror="this.src=''"
                     :src="`https://selfcare.flavia-it.de/user/image/${data.item.ldapName}?size=32&no404=true&noCache=false`"></img>
                <span v-if="data.item.firstName">{{ data.item.firstName }} {{ data.item.lastName }}</span>
                <span v-else>{{ data.item.email }}</span>
              </div>
            </template>
          </v-autocomplete>
        </div>
        <div>
          <v-text-field
            class="comment"
            :label="commentLabel"
            v-model="comment"
          ></v-text-field>
        </div>
      </div>
      <div class="submit-vacation-btn">
        <v-btn
          block
          class="mr-4"
          text
          color="primary"
          :disabled="loading"
          @click="handleSubmit"
        >{{ submitButtonLabel }}
        </v-btn>
      </div>
    </form>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Bestätigen</v-card-title>
        <v-card-text>Bei externen Projekten: Ist der Urlaub mit dem Kunden besprochen?</v-card-text>
        <v-card-actions class="justify-space-around">
          <v-btn color="primary" text @click="submitRequest">
            Ja
          </v-btn>
          <v-btn color="primary" text @click="toggleDialog"> Nein</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="missingEntriesMessage" max-width="400">
      <v-card>
        <v-card-title class="headline">Ungültige Eingabe</v-card-title>
        <v-card-text v-for="entry in invalidEntries" v-bind:key="entry">
          {{ entry }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="emptyForm(false)"> Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="successMessage" max-width="400">
      <v-card>
        <v-card-title class="headline"> Vielen Dank!</v-card-title>
        <v-card-text> Der Antrag wurde abgeschickt</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="emptyForm(true)"> Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="failedMessage" max-width="400">
      <v-card>
        <v-card-title class="headline"> Entschuldigung</v-card-title>
        <v-card-text> Etwas hat nicht funktioniert</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="toggleFailedMessage">
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import {User, UserMetaData, UserRequestAccuracy} from "@/models";
import {
  DEFAULT_EMAIL,
  PLACEHOLDER_NO_SELECTABLE_DATA,
  WEEK_DAY_SATURDAY,
  WEEK_DAY_SUNDAY
} from "@/constants";
import {getDateContext, getWeekDayEnum, validateEmail, validateFlaviaReceipients} from "@/utils";
import {ABSENCE_DATA_ACTIONS, ABSENCE_DATA_GETTERS, AbsenceSpace} from "@/store/modules/absenceData";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";

import DatePicker from "../common/datePicker.vue";

type HavingEmail = { email: string };

@Component({
  components: {
    datePicker: DatePicker
  }
})
export default class RequestForm extends Vue {
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchUserMetaData)
  fetchUserMetaData!: () => Promise<UserMetaData>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllValidUsers)
  getAllValidUsers!: User[];
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAllValidUsers)
  fetchAllValidUsers!: (
    userRequestAccuracy: UserRequestAccuracy
  ) => Promise<User[]>;

  @Prop({type: Function, required: true}) fetchPreselectedRecipients!: () => Promise<HavingEmail []>;

  @Prop({type: String, required: true}) endpointUrl!: string;

  @Prop({type: String, required: true}) placeHolderUserSelection!: string;

  @Prop({type: String, required: true}) commentLabel!: string;

  @Prop({type: String, required: false, default: null}) commentRequiredMessage!: string;

  @Prop({type: String, required: true}) submitButtonLabel!: string;

  @Prop({type: Boolean, required: false, default: false}) allowWeekend!: boolean;

  @Prop({required: false, default: () => []}) restrictUsers!: string[];

  placeholderNoSelectableData = PLACEHOLDER_NO_SELECTABLE_DATA;

  fromDate: string | null = null;
  toDate: string | null = null;
  dialog: boolean = false;
  fromMenu = false;
  toMenu = false;
  usersLoaded = false;
  selectedContacts: string[] = [];
  searchText = "";
  invalid: string = "";
  comment: string = "";
  missingEntriesMessage: boolean = false;
  successMessage: boolean = false;
  failedMessage: boolean = false;
  invalidEntries: string[] = [];
  loading: boolean = false;

  transactionStatus = "";
  snackbar = false;
  transactionError = false;

  hasMissingEntries() {
    this.invalidEntries = [];
    if (
      this.fromDate == null ||
      this.toDate == null ||
      this.selectedContacts.length === 0 ||
      this.comment.length > 500 ||
      (this.commentRequiredMessage && this.comment.length === 0)
    ) {
      if (this.fromDate == null) {
        this.invalidEntries.push(
          "Es muss ein gültiges Startdatum angegeben werden."
        );
      }
      if (this.toDate == null) {
        this.invalidEntries.push(
          "Es muss ein gültiges Enddatum angegeben werden."
        );
      }
      if (this.selectedContacts.length === 0) {
        this.invalidEntries.push(
          "Es muss mindestens ein Ansprechpartner angegeben werden."
        );
      }
      if (this.comment.length > 500) {
        this.invalidEntries.push(
          `Ein Kommentar darf höchstens 500 Zeichen lang sein, der eingegebene Kommentar hat ${this.comment.length} Zeichen.`
        );
      }
      if (this.commentRequiredMessage && this.comment.length === 0) {
        this.invalidEntries.push(this.commentRequiredMessage);
        this.missingEntriesMessage = true;
      }
      this.missingEntriesMessage = true;
      return true;
    }

    return false;
  }

  get isUserSelectionRestricted() {
    return this.restrictUsers.length > 0;
  }

  setDefaultSelectedContact() {
    this.selectedContacts = [DEFAULT_EMAIL];
  }

  get availableContacts(): HavingEmail [] {
    if (this.isUserSelectionRestricted) {
      return this.restrictUsers.map(e => ({email: e}));
    }

    return [
      ...this.getAllValidUsers || [],
      ...this.selectedContacts.map(email => ({email})),
      {email: DEFAULT_EMAIL}
    ];
  }


  async mounted() {
    if (!this.isUserSelectionRestricted && process.env.NODE_ENV === "production") {
      this.setDefaultSelectedContact();
    }

    let now = new Date();

    // await both so that this.getAllValidUsers will have been fetched
    await Promise.all([
      this.fetchAllValidUsers( { year: now.getFullYear(), month: now.getMonth() }),
      this.fetchPreselectedRecipients()
    ])
      .then(([_, lastRecipients]) => {
        this.selectedContacts = [
          ...this.selectedContacts,
          ...lastRecipients.filter(r => validateFlaviaReceipients(r.email)).map(mail => mail.email)
        ]
      }).catch(error => console.log(error))
      .finally(() => (this.usersLoaded = true));
  }

  change() {
    this.searchText = "";
  }

  handleEnteredEmail() {
    if (!validateEmail(this.searchText)) {
      // no valid mail. default autocomplete behavior (select first)
      return;
    }

    if (!validateFlaviaReceipients(this.searchText)) {
      alert("Nur @flavia-it.de und @gridundco.de Adressen erlaubt!");
      return;
    }

    if ( // not already selected
      !this.getAllValidUsers.some(user => user.email === this.searchText)
      && !this.selectedContacts.some(mail => mail === this.searchText)
    ) {
      // add mail to selection
      this.selectedContacts.push(this.searchText);
    }

    // reset input
    this.searchText = "";
  }

  customFilter(item: User, queryText: string): boolean {
    const {firstName = "", lastName = "", email = ""} = item;
    const searchTerm = queryText.toLowerCase();

    if (!firstName && !lastName) {
      return email.indexOf(searchTerm) !== -1;
    }

    return (
      email.toLowerCase().includes(searchTerm) ||
      `${firstName.toLowerCase()} ${lastName.toLowerCase()}`.includes(
        searchTerm
      )
    );
  }

  clearSelectedContact(contactToRemove: HavingEmail) {
    this.selectedContacts = this.selectedContacts.filter(email => {
      return contactToRemove.email !== email;
    });
  }

  isAWeekendDay(date: string): boolean {
    const tzIndependentDate = getDateContext(date);

    return [
      getWeekDayEnum(WEEK_DAY_SATURDAY),
      getWeekDayEnum(WEEK_DAY_SUNDAY)
    ].includes(tzIndependentDate.getDay());
  }

  // allow date input based on another date, because fromDate must be before toDate
  isDateAllowed(date: string, dateToCompareWith: "fromDate" | "toDate") {
    // based on the parameter we select either fromDate or toDate into dateToCompare
    const dateToCompare = this[dateToCompareWith];

    // maybe dont allow to select days on weekends
    if (!this.allowWeekend && this.isAWeekendDay(date)) {
      return false;
    }

    // if the other date is not defined, always allowed
    if (!dateToCompare) {
      return true;
    }

    // fromDate must be before toDate
    return dateToCompareWith === "toDate"
      ? new Date(dateToCompare) >= new Date(date)
      : new Date(dateToCompare) <= new Date(date);
  }

  toggleDialog(): void {
    this.dialog = !this.dialog;
  }

  enableLoading(): void {
    this.loading = true;
  }

  disableLoading(): void {
    this.loading = false;
  }

  updateFromDate(date: string): void {
    this.fromDate = date;

    if (!this.toDate) {
      this.toDate = date;
    }
  }

  updateToDate(date: string): void {
    this.toDate = date;

    if (!this.fromDate) {
      this.fromDate = date;
    }
  }

  handleSubmit(_e: MouseEvent) {
    if (this.hasMissingEntries()) {
      return;
    }

    this.toggleDialog();
  }

  submitRequest() {
    this.enableLoading();

    if (this.fromDate == null || this.toDate == null) {
      this.transactionStatus = "Startdatum und/oder Enddatum nicht ausgewählt";
      this.snackbar = true;
      this.transactionError = true;
    }

    if (
      !this.isDateAllowed(this.fromDate as string, "toDate") ||
      !this.isDateAllowed(this.toDate as string, "fromDate")
    ) {
      this.transactionStatus = "Startdatum und/oder Enddatum falsch angegeben";
      this.snackbar = true;
      this.transactionError = true;
    }

    const postData = {
      employeeId: this.userMetaData.userId,
      startDate: this.fromDate,
      endDate: this.toDate,
      comment: this.comment,
      recipientEmails: this.selectedContacts
    };

    this.toggleDialog();

    this.axios
      .post(this.endpointUrl, postData)
      .then(() => {
        this.successMessage = true;
      })
      .catch(error => {
        if (error.response.status === 409) {
          this.transactionStatus =
            "Für diesen Zeitraum wurde bereits angefragt.";
          this.snackbar = true;
          this.transactionError = true;
        } else if (
          error.response.data ===
          "Recipients must contain at least one person of the mandatory Mail-Addresses"
        ) {
          this.transactionStatus =
            "Es muss mindestens eine Person aus der Geschäftsleitung angegeben werden";
          this.transactionError = true;
          this.snackbar = true;
        } else {
          this.transactionStatus = error.response.data;
          this.failedMessage = true;
        }
      })
      .finally(() => {
        this.disableLoading();
      });
  }

  toggleFailedMessage() {
    this.failedMessage = !this.failedMessage;
  }

  emptyForm(success: boolean): void {
    if (success) {
      this.successMessage = false;
      this.fromDate = this.toDate = null;
      this.comment = "";
    } else {
      while (this.invalidEntries.length > 0) {
        this.invalidEntries.pop();
      }
      this.missingEntriesMessage = false;
    }
  }

  onCloseSnackbar() {
    this.snackbar = false;
    this.transactionStatus = "";
  }
}
</script>

<style lang="scss">
.request-form-layout {
  margin: 0 15px;

  .submit-vacation-btn {
    margin: 15px auto;
    max-width: 75%;

    button {
      min-height: 40px;
      height: fit-content;

      .v-btn__content {
        font-size: 18px;
        white-space: normal;
      }
    }
  }
}

#loadingSpinner {
  width: 100%;
  height: 80%;
  position: absolute;
  background: url("../../assets/loader.gif") center center no-repeat;
}

.contact-selection {
  background-color: var(--v-primary-lighten5);
  padding: 10px 30px;

  > div {
    margin-top: 0;
    padding-top: 10px;
  }
}
</style>
